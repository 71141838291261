import { Snackbar, Alert } from "@mui/material";
import { useState, useEffect } from "react";

// Note! This component is used in an unorthodox way. It's contents are changed on a code level, whenever a new notification has to be shown.
// infoText variable's value is changed to show a notification.
export function Notification() {
  const [open, setOpen] = useState(true);
  const [notification, setNotification] = useState({
    text: "",
    severity: "info",
  });

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const notificationFileUrl = `${process.env.PUBLIC_URL}/notification.txt`;
    fetch(notificationFileUrl)
      .then((response) => response.json())
      .then((notification) => {
        setNotification(notification);
      })
      .catch((error) =>
        console.error("Error fetching notification file.", error)
      );
  }, []);

  const infoText = notification.text; // If infoText variable has a non-empty string, a notification is shown.
  const severity = notification.severity; // accepted values are "error" (red), "warning" (yellow), "info" (blue) or "success" (green)

  return infoText === "" ? null : (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ minWidth: "50vw" }}
      >
        {infoText}
      </Alert>
    </Snackbar>
  );
}
