import { Link, useTheme } from "@mui/material";

export function TermsAndCondition() {
  const theme = useTheme();
  return (
    <Link
      href="/privacy_statement_and_terms_of_use.pdf"
      target="_blank"
      rel="noreferrer"
      underline="none"
      fontWeight="bold"
      sx={{
        ":hover": {
          color: theme.palette.secondary.main,
        },
      }}
    >
      TERMS AND CONDITIONS
    </Link>
  );
}
