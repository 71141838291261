import { useMemo, useState } from "react";
import api from "../../api/api";
import { isEmailValid, refreshAuthToken } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import { appearanceNadr } from "../../utilities/appearanceCustomization";

export const useProducts = (appearance) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [customersWithProducts, setCustomersWithProducts] = useState([]);

  // fetchProducts is memoized in order for it to not cause a rerender in ProductList's useEffect hook
  const fetchCustomersWithProducts = useMemo(() => {
    return async (email) => {
      setLoading(true);

      const response = await getUserInfo(email, navigate);

      if (response.errorMessage === undefined) {
        // From the response, only show the customers that have a productList. TODO: These customers shouldn't be returned from the backend.
        const customersWithProducts =
          response.userInfo.customerList == null
            ? []
            : response.userInfo.customerList.filter(
                (customer) => customer.productList !== null
              );

        setCustomersWithProducts(
          customersWithProducts.map((customer) => ({
            id: customer.uuid,
            name: customer.customerName,
            products: customer.productList.map((apiProduct) =>
              getProduct(apiProduct, customer.uuid, appearance)
            ),
          }))
        );
      } else {
        setErrorMessage(response.errorMessage);
      }

      setLoading(false);
    };
  }, [navigate, appearance]);

  return {
    fetchCustomersWithProducts,
    customersWithProducts,
    errorMessage,
    loading,
  };
};

const getUserInfo = async (email, navigateFunction) => {
  if (!isEmailValid(email)) {
    return {
      userInfo: undefined,
      errorMessage: "Invalid email",
    };
  }

  try {
    let response = undefined;
    try {
      response = await api.client().getUserById(email);
    } catch (error) {
      // If response is Unauthorized, use refresh token and try again
      if (error.response.status === 401) {
        await refreshAuthToken(navigateFunction);
        response = await api.client().getUserById(email);
      }
    }

    if (response.data === undefined) {
      return {
        userInfo: undefined,
        errorMessage: response.errorMessage,
      };
    }

    if (response.data.success === false) {
      return {
        userInfo: undefined,
        errorMessage: response.data.message,
      };
    }

    // response.data.success === true
    return {
      userInfo: response.data.data,
      errorMessage: undefined,
    };
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      return {
        userInfo: undefined,
        errorMessage: "Please check your network",
      };
    }
  }

  return {
    userInfo: undefined,
    errorMessage: "An unknown error occured trying to get user info",
  };
};

function getProduct(apiProduct, customerId, appearance) {
  const links = apiProduct.links.map(({ text, url, additionalInfo }) => ({
    text,
    url,
    additionalInfo,
  }));

  const reportPageUrl =
    appearance === appearanceNadr ? "/nadr/report" : "/report";

  const mainProduct = {
    text: apiProduct.productTitle ?? apiProduct.productName,
    url: `${reportPageUrl}/${customerId}/${apiProduct.uuid}`,
    additionalInfo: null,
  };

  return {
    id: apiProduct.uuid,
    name: apiProduct.productName,
    isPowerBiReport: apiProduct.productType.trim() === "Report",
    links: [mainProduct, ...links], // The main report (or url) is added to links as the first row
    productFamily: apiProduct.productFamily,
  };
}
