import { useState, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Stack,
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { useLogin } from "./useLogin";
import { ErrorText } from "../ErrorText";
import { TermsAndCondition } from "./TermsAndConditions";
import {
  appearanceNadr,
  serviceNameNadr,
  serviceNameNhg,
} from "../../utilities/appearanceCustomization";

function EmailInputBox({ appearance }) {
  const emailInLocalStorage = localStorage.getItem("email");
  //const urlParams = new URLSearchParams(window.location.search);
  //const usePassword = urlParams.get("usePassword");
  const [email, setEmail] = useState(
    // When there's no value in the local storage, localStorage.getItem returns null. That's not acceptable for a controlled input element, so an empty string is used instead.
    emailInLocalStorage === null ? "" : emailInLocalStorage
  );
  const [errorMsg, setErrorMsg] = useState("");
  const { login, loading } = useLogin();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await login(email);

    if (response.success) {
      const destinationSearchParam = searchParams.get("destination");
      const authSelectionUrl = response.requiresPassword ? "/password" : "/otp";
      const baseUrl =
        appearance === appearanceNadr
          ? `/nadr${authSelectionUrl}`
          : authSelectionUrl;

      if (destinationSearchParam === null) {
        navigate(baseUrl);
      } else {
        navigate(
          `${baseUrl}?destination=${encodeURIComponent(destinationSearchParam)}`
        );
      }
    } else {
      setErrorMsg(response.errorMessage);
    }
  };

  const serviceName =
    appearance === appearanceNadr ? serviceNameNadr : serviceNameNhg;

  return (
    <Stack
      minHeight="50%"
      maxHeight="auto"
      width="50%"
      border="2px solid black"
      bgcolor="white"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <form style={{ width: "100%" }}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h4" fontFamily="AeonikPro-Bold">
            Welcome to
            <br />
            {serviceName}
          </Typography>

          <Box width="100%" pt={4}>
            <TextField
              placeholder="Enter your email address"
              value={email}
              onChange={handleEmailChange}
              fullWidth
            />
          </Box>

          {errorMsg === "" ? null : <ErrorText errorMessage={errorMsg} />}

          <Button
            type="submit"
            variant="contained"
            sx={{ width: 180 }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              "Login"
            )}
          </Button>

          <Box pt={2}>
            <TermsAndCondition />
          </Box>
        </Stack>
      </form>
    </Stack>
  );
}

export default EmailInputBox;
