export const appearanceNHG = "NHG";
export const appearanceNadr = "NADR";

export const serviceNameNhg = "NHG Product Portal";
export const serviceNameNadr = "NADR Portal";
export const serviceNameAllCapsNhg = "NHG PRODUCT PORTAL";
export const serviceNameAllCapsNadr = "NADR PORTAL";
export const heroTextNhg =
  "We maximize outcomes and solve today’s pressing health and social care challenges – to improve the lives of everyone";
export const heroTextNadr =
  "Transforming Patient-Centered Care, One Relationship at a Time";

export const logoUrlNadr = "/NADR-logo-300.png";
