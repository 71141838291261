import { Box, useTheme } from "@mui/material";

function Footer() {
  const theme = useTheme();
  return (
    <Box
      bgcolor={theme.palette.primary.main}
      color="white"
      width="100%"
      textAlign="center"
      pt={2}
      pb={2}
    >
      COPYRIGHT © NHG.FI {new Date().getFullYear()}
    </Box>
  );
}

export default Footer;
