import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Stack,
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { useLogin } from "./useLogin";
import { ErrorText } from "../ErrorText";
import { appearanceNadr } from "../../utilities/appearanceCustomization";

function PasswordInputBox({ appearance }) {
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { loading, verifyPassword } = useLogin();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email] = useState(localStorage.getItem("email"));

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await verifyPassword(email, password);

    if (response.success) {
      const destinationSearchParam = searchParams.get("destination");

      if (destinationSearchParam === null) {
        const homeUrl = appearance === appearanceNadr ? "/nadr/otp" : "/otp";
        navigate(homeUrl);
      } else {
        navigate(destinationSearchParam);
      }
    } else {
      setErrorMsg(response.errorMessage);
    }
  };

  return (
    <Stack
      minHeight="50%"
      maxHeight="auto"
      width="50%"
      border="2px solid black"
      bgcolor="white"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <form style={{ width: "100%" }}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h4" fontFamily="AeonikPro-Bold">
            Password Verification
          </Typography>

          <Typography pt={2}>{email}</Typography>

          <Box width="100%">
            <TextField
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
            />
          </Box>

          {errorMsg !== "" ? <ErrorText errorMessage={errorMsg} /> : null}

          <Button
            type="submit"
            variant="contained"
            sx={{ width: 180 }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              "Verify"
            )}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default PasswordInputBox;
