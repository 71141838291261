import { useMediaQuery, useTheme, Stack } from "@mui/material";
import logo from "../logo.svg";
import {
  appearanceNadr,
  logoUrlNadr,
} from "../utilities/appearanceCustomization";

export function Logo({ appearance }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const height = isSmallScreen ? 100 : 212;

  if (appearance === appearanceNadr) {
    return (
      <Stack height={height} textAlign="center" justifyContent="center" pl={1}>
        <img src={logoUrlNadr} alt="NADR logo" />
      </Stack>
    );
  }
  return <img src={logo} height={height} alt="NHG logo" />;
}
