import PasswordInputBox from "./PasswordInputBox";
import LoginViewBase from "./LoginViewBase";

function PasswordVerification({ appearance }) {
  return (
    <LoginViewBase appearance={appearance}>
      <PasswordInputBox appearance={appearance} />
    </LoginViewBase>
  );
}

export default PasswordVerification;
