import { useState } from "react";
import api from "../../api/api";
import {
  isEmailValid,
  getAuthTokens,
  isTokenUndefined,
  setAuthCookies,
} from "../../utilities/helpers";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);

  const login = async (email) => {
    localStorage.setItem("email", email);

    setLoading(true);
    const response = await loginCall(email);
    setLoading(false);

    return response;
  };

  const verifyPassword = async (email, password) => {
    setLoading(true);
    const response = await verifyPasswordCall(email, password);
    setLoading(false);
    return response;
  };

  const validateOtp = async (email, otp) => {
    setLoading(true);
    const response = await validateOtpCall(email, otp);
    setLoading(false);

    return response;
  };

  return {
    login,
    loading,
    verifyPassword,
    validateOtp,
    isLoggedIn,
  };
};

const loginCall = async (email) => {
  if (!isEmailValid(email)) {
    return {
      success: false,
      errorMessage: "Invalid email",
    };
  }

  try {
    const response = await api.client().authenticateUser(email);

    if (response.data.data === true) {
      return {
        success: true,
        requiresPassword: response.data.requiresPassword,
        errorMessage: undefined,
      };
    }

    return {
      success: false,
      errorMessage: response.data.message,
    };
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      return {
        success: false,
        errorMessage: "Please check your network",
      };
    }
  }

  return {
    success: false,
    errorMessage: "An unknown error occured trying to authenticate",
  };
};

const verifyPasswordCall = async (email, password) => {
  if (!isEmailValid(email)) {
    return {
      success: false,
      errorMessage: "No email found or email is invalid",
    };
  }

  try {
    const response = await api.client().verifyPassword({ email, password });

    if (response.data.data !== null && response.data.success) {
      return {
        success: true,
        errorMessage: undefined,
      };
    }

    return {
      success: false,
      errorMessage: response.data.message,
    };
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      return {
        success: false,
        errorMessage: "Please check your network",
      };
    } else {
      return {
        success: false,
        errorMessage:
          error.code + "\nemail: " + email + "\n" + JSON.stringify(error),
      };
    }
  }
};

const validateOtpCall = async (email, otp) => {
  if (!isEmailValid(email)) {
    return {
      success: false,
      errorMessage: "No email found or email is invalid",
    };
  }

  if (otp.length !== 5) {
    return {
      success: false,
      errorMessage: "OTP must be 5 numbers",
    };
  }

  try {
    const response = await api.client().validateOTP({ email, otp });

    const { data, message } = response.data;

    if (data !== null) {
      setAuthCookies(data.requestToken, data.refreshToken);

      return {
        success: true,
        errorMessage: undefined,
      };
    }

    return {
      success: false,
      errorMessage: message,
    };
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      return {
        success: false,
        errorMessage: "Please check your network",
      };
    }
  }

  return {
    success: false,
    errorMessage: "An unknown error occured trying to validate OTP",
  };
};

const isLoggedIn = () => {
  const authTokens = getAuthTokens();
  return !isTokenUndefined(authTokens);
};
