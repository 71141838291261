import axios from "axios";
import { getAuthTokens } from "../utilities/helpers";

const apiClient = {
  client(url = process.env.REACT_APP_API_BASE_URL + "Auth/") {
    return {
      authenticateUser: (email) =>
        axios.post(url + "Authenticate", email, {
          headers: {
            "Content-Type": "application/json",
          },
        }),

      refreshToken: (tokenResponse) =>
        axios.post(url + "RefreshToken", tokenResponse, {
          headers: {
            "Content-Type": "application/json",
          },
        }),

      verifyPassword: (obj) =>
        axios.post(
          process.env.REACT_APP_API_BASE_URL + "Auth/VerifyPassword",
          obj,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ),
      validateOTP: (obj) =>
        axios.post(
          process.env.REACT_APP_API_BASE_URL + "Auth/ValidateOTP",
          obj,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        ),
      getRefreshTokenByAccessToken: (accesstoken) =>
        axios.post(
          process.env.REACT_APP_API_BASE_URL +
            "Customer/GetRefreshTokenByAccessToken",
          accesstoken,
          {
            headers: {
              Authorization: `Bearer ${getAuthTokens().requestToken}`,
              "Content-Type": "application/json",
            },
          }
        ),

      // TODO: Fix function name, should be getUserByEmail
      getUserById: (email) =>
        axios.post(
          process.env.REACT_APP_API_BASE_URL + "User/GetUserById",
          email,
          {
            headers: {
              Authorization: `Bearer ${getAuthTokens().requestToken}`,
              "Content-Type": "application/json",
            },
          }
        ),

      getProductDetails: (obj) =>
        axios.post(
          process.env.REACT_APP_API_BASE_URL + "User/GetProductDetails",
          obj,
          {
            headers: {
              Authorization: `Bearer ${getAuthTokens().requestToken}`,
              "Content-Type": "application/json",
            },
          }
        ),

      // Admin WIP
      getProducts: () =>
        axios.get(process.env.REACT_APP_API_BASE_URL + "Product/GetProducts", {
          headers: {
            Authorization: `Bearer ${getAuthTokens().requestToken}`,
            "Content-Type": "application/json",
          },
        }),
    };
  },
};

export default apiClient;
