import {
  Container,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Hidden,
} from "@mui/material";
import { Logo } from "../Logo";
import { useLogin } from "./useLogin";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  appearanceNadr,
  heroTextNadr,
  heroTextNhg,
  serviceNameAllCapsNadr,
  serviceNameAllCapsNhg,
} from "../../utilities/appearanceCustomization";

function LoginViewBase({ children, appearance }) {
  const { isLoggedIn } = useLogin();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn()) {
      const mainPageUrl = appearance === appearanceNadr ? "/nadr" : "/";
      navigate(mainPageUrl);
    }
  }, [navigate, isLoggedIn, appearance]);

  const serviceName =
    appearance === appearanceNadr
      ? serviceNameAllCapsNadr
      : serviceNameAllCapsNhg;

  const heroText = appearance === appearanceNadr ? heroTextNadr : heroTextNhg;

  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down("sm"));

  const stackDirection = isSmallScreen ? "column" : "row";
  const stackWidth = isSmallScreen ? "100%" : "50%";
  const typographyVariant = isSmallScreen ? "h4" : "h1";
  const textHeight = isSmallScreen ? "20vh" : "100vh";
  const loginBoxHeight = isSmallScreen ? "80vh" : "100vh";

  return (
    <Container maxWidth="false" disableGutters>
      <Stack direction={stackDirection} justifyContent="center">
        <Stack
          height={textHeight}
          width={stackWidth}
          justifyContent="center"
          alignItems="center"
        >
          <Logo appearance={appearance} />
          <Hidden smDown>
            <Typography
              variant={typographyVariant}
              fontFamily="SharpGroteskBold"
            >
              {serviceName}
            </Typography>
          </Hidden>
          <Hidden smDown>
            <Stack maxWidth="80%" pt={5}>
              <Typography variant="h4">{heroText}</Typography>
            </Stack>
          </Hidden>
        </Stack>
        <Stack
          height={loginBoxHeight}
          width={stackWidth}
          justifyContent="center"
          alignItems="center"
          bgcolor={theme.palette.secondary.light}
        >
          {children}
        </Stack>
      </Stack>
    </Container>
  );
}

export default LoginViewBase;
