import EmailInputBox from "./EmailInputBox";
import LoginViewBase from "./LoginViewBase";

function Login({ appearance }) {
  return (
    <LoginViewBase appearance={appearance}>
      <EmailInputBox appearance={appearance} />
    </LoginViewBase>
  );
}

export default Login;
