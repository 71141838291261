import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Logo } from "../Logo";
import { expireAuthCookies } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import {
  appearanceNadr,
  serviceNameAllCapsNadr,
  serviceNameAllCapsNhg,
} from "../../utilities/appearanceCustomization";

function Header({ appearance }) {
  const email = localStorage.getItem("email");
  const domains = ["nhgdk.dk", "kuntamaisema.fi", "nhg.dk", "nhg.se", "nhg.fi"];
  const emailDomain = email.split("@")[1];

  const navigate = useNavigate();
  const theme = useTheme();

  const logout = () => {
    expireAuthCookies();

    const loginPageUrl =
      appearance === appearanceNadr ? "/nadr/login" : "/login";
    navigate(loginPageUrl);
  };

  const headerText =
    appearance === appearanceNadr
      ? serviceNameAllCapsNadr
      : serviceNameAllCapsNhg;

  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down("sm"));
  const typographyVariant = isSmallScreen ? "h4" : "h1";

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Logo appearance={appearance} />
      <Typography variant={typographyVariant} fontFamily="SharpGroteskBold">
        {headerText}
      </Typography>
      {/* Logout button's container needs to have the same width as the logo to ensure the company name text is in the middle of the screen */}
      <Stack width={300} alignItems="center">
        <Button
          variant="text"
          sx={{ fontWeight: "bold", fontSize: "15px" }}
          disableRipple
          onClick={logout}
        >
          LOGOUT
        </Button>
        {domains.includes(emailDomain) ? (
          <Button
            variant="text"
            sx={{ fontWeight: "bold", fontSize: "15px" }}
            disableRipple
            href="https://prod-nhg-customerportal-admin-app.azurewebsites.net/"
            target="_blank"
            /* Admin WIP */
            /*href="/products"*/
            rel="noreferrer"
          >
            ADMIN TOOL
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default Header;
