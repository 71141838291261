import { Route, Routes as RouterRoutes, Navigate } from "react-router-dom";
import Login from "./components/login/Login";
import PasswordVerification from "./components/login/PasswordVerification";
import OTP from "./components/login/OTPVerification";
import Dashboard from "./components/client-dashboard/Dashboard";
import { Report } from "./components/report/Report";
import Products from "./components/admin/Products";
import {
  appearanceNadr,
  appearanceNHG,
} from "./utilities/appearanceCustomization";

export function Routes() {
  return (
    <RouterRoutes>
      <Route path="/login" element={<Login appearance={appearanceNHG} />} />
      <Route
        path="/password"
        element={<PasswordVerification appearance={appearanceNHG} />}
      />
      <Route path="/otp" element={<OTP appearance={appearanceNHG} />} />
      <Route path="/" element={<Dashboard appearance={appearanceNHG} />} />
      <Route
        path="/report/:customerId/:productId"
        element={<Report appearance={appearanceNHG} />}
      />

      <Route
        path="/nadr/login"
        element={<Login appearance={appearanceNadr} />}
      />
      <Route path="/nadr/otp" element={<OTP appearance={appearanceNadr} />} />
      <Route path="/nadr" element={<Dashboard appearance={appearanceNadr} />} />
      <Route
        path="/nadr/report/:customerId/:productId"
        element={<Report appearance={appearanceNadr} />}
      />

      {/* Some people have /dashboard saved to a bookmark, so we'll redirect them to frontpage */}
      <Route path="/dashboard" element={<Navigate to="/" />} />

      <Route path="/nadr/*" element={<Navigate to="/nadr" />} />
      <Route path="/*" element={<Navigate to="/" />} />
      {/* Admin WIP */}
      <Route
        path="/products"
        element={<Products appearance={appearanceNHG} />}
      />
    </RouterRoutes>
  );
}
