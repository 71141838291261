import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Stack,
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { useLogin } from "./useLogin";
import { ErrorText } from "../ErrorText";
import { appearanceNadr } from "../../utilities/appearanceCustomization";

function OTPInputBox({ appearance }) {
  const [otp, setOTP] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { login, loading, validateOtp } = useLogin();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [email] = useState(localStorage.getItem("email"));

  const handleOtpChange = (event) => {
    const inputWithOnlyNumbers = event.target.value.replace(/[^0-9]/g, "");
    setOTP(inputWithOnlyNumbers);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await validateOtp(email, otp);

    if (response.success) {
      const destinationSearchParam = searchParams.get("destination");

      if (destinationSearchParam === null) {
        const homeUrl = appearance === appearanceNadr ? "/nadr" : "/";
        navigate(homeUrl);
      } else {
        navigate(destinationSearchParam);
      }
    } else {
      setErrorMsg(response.errorMessage);
    }
  };

  const resendOtp = () => {
    void login(email);
  };

  return (
    <Stack
      minHeight="50%"
      maxHeight="auto"
      width="50%"
      border="2px solid black"
      bgcolor="white"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <form style={{ width: "100%" }}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h4" fontFamily="AeonikPro-Bold">
            One Time
            <br />
            Password Verification
          </Typography>

          <Typography pt={2}>{email}</Typography>

          <Box width="100%">
            <TextField
              placeholder="Enter 5 digit code from your email"
              value={otp}
              onChange={handleOtpChange}
              fullWidth
            />
          </Box>

          {errorMsg !== "" ? <ErrorText errorMessage={errorMsg} /> : null}

          <Stack direction="row">
            <Typography variant="body1">
              Haven’t received One Time Password?
            </Typography>
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={resendOtp}
              pl={0.5}
            >
              Resend OTP
            </Typography>
          </Stack>

          <Button
            type="submit"
            variant="contained"
            sx={{ width: 180 }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              "Verify"
            )}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

export default OTPInputBox;
