import { useState, useMemo } from "react";
import api from "../../api/api";
import { refreshAuthToken, expireAuthCookies } from "../../utilities/helpers";
import * as pbi from "powerbi-client";
import { useNavigate } from "react-router-dom";

export const useReportConfiguration = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const navigate = useNavigate();

  const getReportConfigurationAndUrl = useMemo(() => {
    return async (productId, customerId, pageId) => {
      setLoading(true);

      const response = await getReportConfigurationCall(
        productId,
        customerId,
        navigate,
        pageId
      );
      setErrorMessage(response.errorMessage);

      setLoading(false);

      const { configuration, reportUrl } = response;
      return { configuration, reportUrl };
    };
  }, [navigate]);

  return {
    errorMessage,
    loading,
    getReportConfigurationAndUrl,
  };
};

// Returns a configuration object that is required to open the product report
const getReportConfigurationCall = async (
  productId,
  customerId,
  navigateFunction,
  pageId
) => {
  try {
    const payload = {
      customerId,
      productId,
      productType: "Report",
    };

    let response = undefined;
    try {
      response = await api.client().getProductDetails(payload);
    } catch (error) {
      // If response is Unauthorized, use refresh token and try again
      if (error.response.status === 401) {
        try {
          await refreshAuthToken(navigateFunction);
          response = await api.client().getProductDetails(payload);
        } catch (refreshError) {
          // If refresh token is also Unauthorized, redirect to login
          // and expire the cookies
          expireAuthCookies();
          navigateFunction("/login");
          return {
            reportUrl: undefined,
            configuration: undefined,
            errorMessage: refreshError.errorMessage,
          };
        }
      }
    }

    if (response.data === undefined) {
      return {
        reportUrl: undefined,
        configuration: undefined,
        errorMessage: response.errorMessage,
      };
    }

    const { success, data } = response.data;
    if (success === false) {
      return {
        reportUrl: undefined,
        configuration: undefined,
        errorMessage: response.data.message,
      };
    }

    // response.data.success === true
    const { reportId, accessToken, workspaceId, reportURL } = data;

    // Power BI report
    if (reportURL === "") {
      return {
        reportUrl: undefined,
        configuration: getConfiguration(
          reportId,
          accessToken,
          workspaceId,
          pageId
        ),
        errorMessage: undefined,
      };
    }
    // Link to an openly accessible url
    return {
      reportUrl: reportURL,
      configuration: undefined,
      errorMessage: undefined,
    };
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      return {
        reportUrl: undefined,
        configuration: undefined,
        errorMessage: "Please check your network",
      };
    }
  }

  return {
    reportUrl: undefined,
    configuration: undefined,
    errorMessage:
      "An unknown error occured trying to get report's configuration",
  };
};

const getConfiguration = (reportId, accessToken, workspaceId, pageId) => {
  let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${workspaceId}`;

  if (pageId !== undefined) {
    embedUrl += `&pageName=${pageId}`;
  }

  return {
    type: "report",
    tokenType: pbi.models.TokenType.Embed,
    accessToken: accessToken,
    embedUrl,
    permissions: pbi.models.Permissions.All,
    settings: {
      persistentFiltersEnabled: true,
      personalBookmarksEnabled: true,
      bookmarks: {
        visible: false,
      },
      filters: {
        visible: false,
      },
      pageNavigation: {
        visible: true,
        position: pbi.models.PageNavigationPosition.Bottom,
      },
    },
  };
};
