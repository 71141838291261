// Admin WIP

import { useEffect } from "react";
import Header from "../client-dashboard/Header";
import Footer from "../client-dashboard/Footer";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../login/useLogin";
import { appearanceNadr } from "../../utilities/appearanceCustomization";
import { useAdminProducts } from "./useAdminProducts";

function Products({ appearance }) {
  const navigate = useNavigate();
  const { isLoggedIn } = useLogin();

  const loggedIn = isLoggedIn();
  useEffect(() => {
    if (!loggedIn) {
      const loginPageUrl =
        appearance === appearanceNadr ? "/nadr/login" : "/login";
      navigate(loginPageUrl);
    }
  }, [navigate, loggedIn, appearance]);

  const { fetchProducts, products, loading, errorMessage } =
    useAdminProducts(appearance);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  if (loggedIn) {
    return (
      <Stack justifyContent="space-between" minHeight="100vh">
        <Box>
          <Header appearance={appearance} />
          <p>fetchProducts: {JSON.stringify(fetchProducts)}</p>
          <p>loading: {JSON.stringify(loading)}</p>
          <p>errorMessage: {JSON.stringify(errorMessage)}</p>
          <p>products: {JSON.stringify(products)}</p>
        </Box>
        <Box pt={3}>
          <Footer />
        </Box>
      </Stack>
    );
  }
}

export default Products;
