import {
  Link,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export function ProductLinks({ product }) {
  const [accordionOpen, setAccordionOpen] = useState(true);

  const handleAccordionChange = () => {
    setAccordionOpen((previousOpenState) => !previousOpenState);
  };

  return (
    <Accordion
      sx={{ boxShadow: "none", p: 0, pb: accordionOpen ? 0 : 1 }}
      expanded={accordionOpen}
      onChange={handleAccordionChange}
      disableGutters
    >
      {/* Lowering the height of the accordion is tricky. There might be a prettier solution available. */}
      <AccordionSummary
        sx={{ height: 0, minHeight: 0, paddingBottom: 1, paddingTop: 3 }}
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="body1" fontFamily="AeonikPro-Bold" pb={1}>
            {product.name}
          </Typography>
          <Arrow accordionOpen={accordionOpen} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {getProductLinkElements(product.links)}
      </AccordionDetails>
    </Accordion>
  );
}

function getProductLinkElements(links) {
  // Separate the first item and the rest of the array
  let firstItem = links[0];
  let restOfItems = links.slice(1);

  // Sort the rest of the array alphabetically based on the `text` property
  restOfItems.sort((a, b) => a.text.localeCompare(b.text));

  // Concatenate the first item with the sorted array
  let finalSortedLinks = [firstItem, ...restOfItems];
  return finalSortedLinks.map((link) => (
    <Stack
      key={link.url}
      pb={1}
      pl={2}
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <Link href={link.url} target="_blank">
        {link.text}
      </Link>
      {link.additionalInfo === null ? null : (
        <span style={{ fontStyle: "italic" }}>{link.additionalInfo}</span>
      )}
      <br />
    </Stack>
  ));
}

function Arrow({ accordionOpen }) {
  return (
    <Box fontSize="150%">
      <KeyboardArrowDownIcon
        fontSize="large"
        htmlColor="black"
        sx={{ transform: accordionOpen ? "rotate(180deg)" : "" }}
      />
    </Box>
  );
}
