import OTPInputBox from "./OTPInputBox";
import LoginViewBase from "./LoginViewBase";

function OTPVerification({ appearance }) {
  return (
    <LoginViewBase appearance={appearance}>
      <OTPInputBox appearance={appearance} />
    </LoginViewBase>
  );
}

export default OTPVerification;
