import { useEffect } from "react";
import Header from "./Header";
import ProductListContainer from "./ProductListContainer";
import Footer from "./Footer";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../login/useLogin";
import { appearanceNadr } from "../../utilities/appearanceCustomization";

function Dashboard({ appearance }) {
  const navigate = useNavigate();
  const { isLoggedIn } = useLogin();

  const loggedIn = isLoggedIn();
  useEffect(() => {
    if (!loggedIn) {
      const loginPageUrl =
        appearance === appearanceNadr ? "/nadr/login" : "/login";
      navigate(loginPageUrl);
    }
  }, [navigate, loggedIn, appearance]);

  if (loggedIn) {
    return (
      <Stack justifyContent="space-between" minHeight="100vh">
        <Box>
          <Header appearance={appearance} />
          <ProductListContainer appearance={appearance} />
        </Box>
        <Box pt={3}>
          <Footer />
        </Box>
      </Stack>
    );
  }
}

export default Dashboard;
