import { Stack, Typography, Box, useTheme, Button } from "@mui/material";
import { ProductLinks } from "./ProductLinks";

export function ProductFamily({ productFamily, products }) {
  const theme = useTheme();

  const sortedProducts = [...products].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const feedbackUrls = {
    Dental: "https://link.webropolsurveys.com/S/30079F8ED6EE0C27",
    "Health and social": "https://link.webropolsurveys.com/S/34C7D597797C30EB",
    Elderly: "https://link.webropolsurveys.com/S/36BB3BB141AC950E",
  };

  return (
    <Stack>
      <Box borderBottom="2px solid" display="flex" alignItems="center">
        <Typography
          fontFamily="SharpGroteskBold"
          fontSize={60}
          color={theme.palette.secondary.main}
        >
          {productFamily.toUpperCase()}
        </Typography>
        {feedbackUrls[productFamily] && (
          <Box marginLeft="auto">
            <Button
              variant="contained"
              color="secondary"
              href={feedbackUrls[productFamily]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Give feedback
            </Button>
          </Box>
        )}
      </Box>

      {sortedProducts.map((product) => {
        return (
          <Box key={product.id} borderBottom="2px solid">
            <ProductLinks product={product} />
          </Box>
        );
      })}
    </Stack>
  );
}
