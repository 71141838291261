import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ProductFamily } from "./ProductFamily";
import { useLocation } from "react-router-dom";

function ProductList({ customersWithProducts }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showCustomer = queryParams.get("showCustomer");

  const [open, setOpen] = useState(
    customersWithProducts.reduce((acc, curr) => {
      acc[curr.id] = true;
      return acc;
    }, {})
  );

  const handleAccordionChange = (id) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: !prevOpen[id],
    }));
  };
  const nameExists = Object.values(customersWithProducts).some(
    (customer) => customer.name === showCustomer
  );

  if (!nameExists && showCustomer) {
    return 'No customer found with the name"' + showCustomer + '"';
  }

  return customersWithProducts.map((customerWithProducts) => {
    if (customerWithProducts.products.length === 0) {
      return null;
    }

    if (customerWithProducts.name !== showCustomer && showCustomer) {
      return null;
    }

    return (
      <Box width="100%" key={customerWithProducts.id}>
        <Accordion
          expanded={open[customerWithProducts.id] || false}
          onChange={() => handleAccordionChange(customerWithProducts.id)}
        >
          <AccordionSummary
            style={{ userSelect: "text" }}
            expandIcon={
              <Box fontSize="150%">
                <KeyboardArrowDownIcon
                  fontSize="large"
                  htmlColor="black"
                  sx={{
                    transform: open[customerWithProducts.id]
                      ? "rotate(180deg)"
                      : "",
                  }}
                />
              </Box>
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              p={3}
              width="100%"
            >
              <Typography fontFamily="AeonikPro-Bold" fontSize={29}>
                {customerWithProducts.name}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {getUniqueProductFamilies(customerWithProducts.products).map(
              (productFamily) => (
                <Box key={`${productFamily}${customerWithProducts.id}`} pb={6}>
                  <ProductFamily
                    productFamily={productFamily}
                    products={customerWithProducts.products.filter(
                      (p) => p.productFamily === productFamily
                    )}
                  />
                </Box>
              )
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  });
}

export default ProductList;

function getUniqueProductFamilies(products) {
  return [...new Set(products.map((product) => product.productFamily))];
}
