import { Routes } from "./Routes";
import "./App.css";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import { Notification } from "./components/notification/Notification";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Notification />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
