import { v4 as uuidv4 } from "uuid";
import api from "../api/api";

export const getCookie = (name) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
};

export const getCookieValue = () => {
  const refreshToken = getCookie("refreshToken");
  const requestToken = getCookie("requestToken");
  if (
    typeof refreshToken !== "undefined" &&
    typeof requestToken !== "undefined"
  ) {
    const obj = {
      JWTToken: requestToken,
      RefreshToken: refreshToken,
    };
    return obj;
  } else {
    return null;
  }
};

export const isEmailValid = (email) => {
  if (email === null || email === undefined) {
    return false;
  }

  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const getAuthTokens = () => {
  const cookies = Object.fromEntries(
    document.cookie.split("; ").map((c) => {
      const [key, value] = c.trim().split("=");
      return [key, value];
    })
  );
  const requestTokenUuid = Object.keys(cookies).find((key) =>
    key.startsWith("request_token_")
  );
  const refreshTokenUuid = Object.keys(cookies).find((key) =>
    key.startsWith("refresh_token_")
  );
  const requestToken = cookies[requestTokenUuid];
  const refreshToken = cookies[refreshTokenUuid];
  return { requestToken, refreshToken };
};

export const updateAuthCookies = (requestToken, refreshToken) => {
  const existingRequestTokenCookie = getCookieByName("request_token_");
  const existingRefreshTokenCookie = getCookieByName("refresh_token_");

  if (existingRequestTokenCookie) {
    document.cookie = `${existingRequestTokenCookie.name}=${requestToken}; path=/`;
  }

  if (existingRefreshTokenCookie) {
    document.cookie = `${existingRefreshTokenCookie.name}=${refreshToken}; path=/`;
  }
};

// Helper function to get cookie by name
function getCookieByName(name) {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    if (cookie.startsWith(name)) {
      const [cookieName, cookieValue] = cookie.split("=");
      return { name: cookieName, value: cookieValue };
    }
  }
  return null;
}

export const setAuthCookies = (requestToken, refreshToken) => {
  const expirationDate = new Date(Date.now() + 2592000000);
  const expires = "; expires=" + expirationDate.toUTCString();

  const requestTokenCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("request_token_"));

  if (requestTokenCookie) {
    expireTokens();
  }

  const requestTokenUuid = uuidv4();
  const refreshTokenUuid = uuidv4();

  document.cookie =
    "request_token_" +
    requestTokenUuid +
    "=" +
    requestToken +
    expires +
    ";path=/ ";

  document.cookie =
    "refresh_token_" +
    refreshTokenUuid +
    "=" +
    refreshToken +
    expires +
    "; path=/ ";
};

export const expireAuthCookies = () => {
  const cookies = Object.fromEntries(
    document.cookie.split("; ").map((c) => c.split("="))
  );
  const requestTokenUuid = Object.keys(cookies).find((key) =>
    key.startsWith("request_token_")
  );
  const refreshTokenUuid = Object.keys(cookies).find((key) =>
    key.startsWith("refresh_token_")
  );

  if (requestTokenUuid) {
    document.cookie = `${requestTokenUuid}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  if (refreshTokenUuid) {
    document.cookie = `${refreshTokenUuid}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
};

// expire the previous tokens
const expireTokens = () => {
  const requestTokenCookies = getAllCookiesByName("request_token_");
  const refreshTokenCookies = getAllCookiesByName("refresh_token_");

  requestTokenCookies.forEach((cookie) => {
    document.cookie = `${cookie.name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  });

  refreshTokenCookies.forEach((cookie) => {
    document.cookie = `${cookie.name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  });
};

// get all cookies by name
const getAllCookiesByName = (name) => {
  return document.cookie
    .split(";")
    .map((cookie) => cookie.trim())
    .filter((cookie) => cookie.startsWith(name))
    .map((cookie) => {
      const cookieName = cookie.split("=")[0];
      const cookieValue = cookie.split("=")[1];
      return { name: cookieName, value: cookieValue };
    });
};

export const isTokenUndefined = (tokens) => {
  if (
    typeof tokens.requestToken !== "undefined" &&
    typeof tokens.refreshToken !== "undefined" &&
    tokens.requestToken !== "undefined" &&
    tokens.refreshToken !== "undefined" &&
    tokens.requestToken !== "" &&
    tokens.refreshToken !== ""
  ) {
    return false;
  } else {
    return true;
  }
};

export const refreshAuthToken = async (navigateFunction) => {
  const authTokens = getAuthTokens();
  try {
    const response = await api.client().refreshToken({
      RequestToken: authTokens.requestToken,
      RefreshToken: authTokens.refreshToken,
    });
    updateAuthCookies(response.data.requestToken, response.data.refreshToken);
  } catch (error) {
    if (error.response.status === 401) {
      expireTokens();
      navigateFunction("/login");
    }
  }
};
