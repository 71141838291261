import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getAuthTokens, isTokenUndefined } from "../../utilities/helpers";
import { useCallback, useEffect, useState } from "react";
import { useReportConfiguration } from "../client-dashboard/useReportConfiguration";
import { PowerBIEmbed } from "powerbi-client-react";
import { CircularProgress, Stack } from "@mui/material";
import { ErrorText } from "../ErrorText";
import { appearanceNadr } from "../../utilities/appearanceCustomization";

export function Report({ appearance }) {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { customerId, productId } = useParams();
  const [searchParams] = useSearchParams();

  const [reportConfiguration, setReportConfiguration] = useState(undefined);
  const { getReportConfigurationAndUrl, loading, errorMessage } =
    useReportConfiguration();

  const redirectLoggedOutWithReturnDestination = useCallback(() => {
    const authTokens = getAuthTokens();

    if (isTokenUndefined(authTokens)) {
      const encodedUrl = encodeURIComponent(pathname + search);
      const loginPageUrl =
        appearance === appearanceNadr ? "/nadr/login" : "/login";
      navigate(`${loginPageUrl}?destination=${encodedUrl}`);
    } else {
      const initiallyOpenPageId = searchParams.get("pageId");
      void getReportConfigurationAndUrl(
        productId,
        customerId,
        initiallyOpenPageId
      ).then(({ configuration, reportUrl }) => {
        // The configuration will either have the Power BI configuration object, OR a link. In case of an error, both are undefined.

        if (configuration !== undefined) {
          setReportConfiguration(configuration);
        } else if (reportUrl !== undefined) {
          // Navigate to the link
          window.location.href = reportUrl;
        }
      });
    }
  }, [
    pathname,
    search,
    navigate,
    customerId,
    getReportConfigurationAndUrl,
    productId,
    setReportConfiguration,
    searchParams,
    appearance,
  ]);

  useEffect(() => {
    redirectLoggedOutWithReturnDestination(navigate);
  }, [redirectLoggedOutWithReturnDestination, navigate]);

  if (loading) {
    return (
      <Stack width="100%" alignItems="center" p={5}>
        <CircularProgress color="secondary" size={120} />
      </Stack>
    );
  }
  if (errorMessage !== undefined) {
    return (
      <Stack width="100%" alignItems="center" p={5}>
        <ErrorText errorMessage={errorMessage} />
      </Stack>
    );
  }
  if (reportConfiguration !== undefined) {
    return (
      <PowerBIEmbed
        embedConfig={reportConfiguration}
        cssClassName="report-container"
      />
    );
  }
}
